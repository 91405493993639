
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, EstimationClient, FabricEstimationClient, ProductionProcessClient, TransportDistanceCalculationClient, TransportTypeConfigurationClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import editLayerProductionProcess from '../../components/editLayerProductionProcess.vue'
import editLayerTransportation from '../../components/editLayerTransportation.vue'
import viewWeastageData from '../../components/viewWeastageData.vue'
import EditRawMaterialProductionProcessModal from '../../modals/editRawMaterialProductionProcessModal.vue';
import store from '@/store';
import utils from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {
        editLayerProductionProcess,
        editLayerTransportation,
        viewWeastageData
    }
})
export default class Processes extends Vue {

    estimationIdentifier: string = "";

    heightOfRoll: number = 0;
    lengthOfFabric: number = 0;

    productionPhase: string = "";
    productionPhaseIdentifier: string = "";
    allProductionProcesses: OM.ProductionProcessListVM[] = [];
    allProductionProcessesFromConfiguration: OM.ProductionProcessConfigurationVM[] = [];
    
    rawMaterialIndipendentProductionProcesses: OM.FabricProductionProcess[] = [];
    layerProductionProcesses: { [key: string]: OM.FabricProductionProcess[]; } = {};
    layerCompositions: OM.LayerComposition[] = [];
    electricityCountries: OM.TextIdentifier[] = [];

    layerTransportPhases: { [key: string]: OM.TransportProductionPhase[]; } = {};
    transportationCountries: OM.TransportDistanceCalculationVM[] = [];
    transportTypes: string[] = [];
    saveTransportTypesForEveryMaterial: boolean = false;

    showOnlyProcess: boolean = false;
    loaded: boolean = false;

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }
    
    @Watch("$route.params.process")
    init(){
        this.productionPhase = this.$route.params.process ? this.$route.params.process.toString() : "";
        this.saveTransportTypesForEveryMaterial = utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);

        this.showOnlyProcess = false;
        if(this.productionPhase == "Finishing & Packaging")
            this.showOnlyProcess = true;

        if(!this.productionPhase)
            return;

        this.productionPhaseIdentifier = this.$store.state.phases.find( x => x.text == this.productionPhase).identifier;

        Promise.all([
            store.state.estimationClient.getProductionProcessesByProductionPhase(this.estimationIdentifier, this.productionPhaseIdentifier),
            store.state.estimationClient.getEstimationsGarmentLayers(this.estimationIdentifier, this.productionPhaseIdentifier, false),
            store.state.estimationClient.getLayerProductionProcess(this.estimationIdentifier),
            store.state.estimationClient.getLayerTransportProductionPhases(this.estimationIdentifier),
            store.state.estimationClient.getRawMaterialIndipendentProductionProcess(this.estimationIdentifier),
            ElectricityEmissionClient.getCountries(true),
            TransportDistanceCalculationClient.getAllConfigurations(),
            TransportTypeConfigurationClient.getAllTransportTypes(),
            ProductionProcessClient.getAllConfigurationsByProductionPhase(this.productionPhase) //Questa è uguale alla prim penso
        ])
        .then( xs => {
            this.allProductionProcesses = xs[0];
            this.layerCompositions = xs[1];
            this.layerProductionProcesses = xs[2];
            this.layerTransportPhases = xs[3];
            this.rawMaterialIndipendentProductionProcesses = xs[4];
            this.electricityCountries = xs[5];
            this.transportationCountries = xs[6];
            this.transportTypes = xs[7].map(x => x.transportType);
            this.allProductionProcessesFromConfiguration = xs[8];

            // this.electricityCountries.unshift({
            //     text: this.$localizationService.getLocalizedValue("GENERIC", "GENERIC"),
            //     identifier: ""
            // });

            if(this.indipendentProductionProcess.length > 0) {
                FabricEstimationClient.getFabricSpecifics(this.estimationIdentifier)
                .then(x => {
                    this.heightOfRoll = x.heightOfRoll;
                    this.lengthOfFabric = x.lengthOfFabric;

                    this.loaded = true;
                })
            }
            else
                this.loaded = true;
        })
    }

    get indipendentProductionProcess() {
        return this.rawMaterialIndipendentProductionProcesses.filter( x => x.rawMaterialIndipendent)
            .filter( x => x.productionPhase.text == this.productionPhase);
    }

    get editEstimationLayerProductionProcessList() {
        var ris: OM.EditEstimationLayerProductionProcessVM[] = [];

        if(this.allProductionProcesses.length == 0)
            return ris;

        this.indipendentProductionProcess.forEach(element => {
            var production = this.allProductionProcesses.find(x => x.name == element.name);
            if(production) {
                var el = new OM.EditEstimationLayerProductionProcessVM();
                el.identifier = this.estimationIdentifier;
                el.productionProcessIdentifier = production.identifier;
                el.productionCountry = element.productionCountry.identifier;
                el.units = element.units;
                ris.push(el);
            }
        });

        return ris;
    }

    get disabled(){
        return !this.allTransportSetted;
    }
    get allTransportSetted() {
        if(this.productionPhase == "Finishing & Packaging") {
            return this.heightOfRoll && this.lengthOfFabric;
        }

        var disabledCount = 0;

        this.layerCompositions.forEach(layer => {
            layer.fabricCompositions.forEach(fabric => {
                if(this.layerTransportPhases[layer.layerName].filter(x => x.productionPhase == this.productionPhase && x.rawMaterial.identifier == fabric.rawMaterial.identifier).length == 0)
                    disabledCount = disabledCount + 1;
            });
        });

        return disabledCount == 0;
    }

    editAdditionalLayerProductionProcess(item: OM.FabricProductionProcess) {
        var productProcFiltered = this.allProductionProcessesFromConfiguration.filter( x => {
            if(x.uniqueKey == item.uniqueKey)
                return x;
        });

        this.$opModal.show(EditRawMaterialProductionProcessModal, {
            estimationIdentifier: this.estimationIdentifier,
            allProductionProcesses: productProcFiltered,
            countries: this.electricityCountries,
            modelToEdit: item,
            saveCallback: (editEstimationLayerProductionProcessVM : OM.EditEstimationLayerProductionProcessVM) => {
                store.state.estimationClient.editRawMaterialIndipendentProductionProcess(editEstimationLayerProductionProcessVM)
                .then( x => {
                    this.$opModal.closeAll();
                    this.init();
                })
            }
        })
    }

    timeoutId: any = null;
    updateValue(indice: number) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            var updatedEl = this.editEstimationLayerProductionProcessList[indice];
            store.state.estimationClient.editRawMaterialIndipendentProductionProcess(updatedEl)
            .then( x => {
                this.$opModal.closeAll();
                this.init();
            })
        }, 500);
    }

    removeAdditionalLayerProductionProcess(productionProcessName: string){
        var removeEstimationsGarmentLayersVM = new OM.RemoveEstimationLayerProductionProcessVM();
        removeEstimationsGarmentLayersVM.identifier = this.estimationIdentifier;
        removeEstimationsGarmentLayersVM.productionProcessName = productionProcessName;

        store.state.estimationClient.removeRawMaterialIndipendentProductionProcess(removeEstimationsGarmentLayersVM)
        .then( x => {
            this.init();
        })
    }

    next() {
        var currentProcess = this.$store.state.phases.find( x => x.text == this.productionPhase);
        var currentProcessPos = this.$store.state.phases.indexOf(currentProcess);

        if(currentProcessPos == this.$store.state.phases.length - 1) 
        {
            FabricEstimationClient.saveFabricSpecifics(this.estimationIdentifier, this.heightOfRoll, this.lengthOfFabric)
            .then(x => {
                this.$router.push("/fabric-calculator/" + this.estimationIdentifier + "/finaltransportation");
            })
        } 
        else 
        {
            var next = this.$store.state.phases[currentProcessPos + 1];
                
            (store.state.estimationClient as typeof EstimationClient).initializeAllProcessesByRawMaterialsAndProductionPhase(this.estimationIdentifier, next.identifier)
            .then(x => {     
                this.$router.push("/fabric-calculator/productionprocess/" + this.estimationIdentifier + "/" + next.text);
            })
        }
    }

    async saveTransportationCallback(editLayerTransportationVM : OM.EditLayerTransportationVM){
        this.$opModal.closeAll();
        this.init();
    }

    async removeCallback(item: OM.RemoveLayerTransportationVM){
        var allTogether = this.$utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);

        var prom = null;

        if(allTogether)
            prom = store.state.estimationClient.removeAllLayerTransportationByProductionPhase(item);
        else
            prom = store.state.estimationClient.removeLayerTransportation(item);
             
        prom.then(x => {
            this.$opModal.closeAll();
            this.init();
        })
    }
}
